import * as React from 'react';
import { TReactFCP, TReactFCR } from '../../lib/types/utils';
import { UseStyles } from '../../lib/types/mui';
import { Theme, makeStyles } from '@material-ui/core';
import { graphql } from 'gatsby';
import { Page } from '../../components/app/Page';
import { SEO } from '../../components/app/SEO';
import { GQLProjectPersonalWebsiteV2PageQuery } from '../../lib/types/graphql/__generated__/gatsby.gql';
import { Typography } from '../../components/Typography';
import { FluidObject } from 'gatsby-image';
import { ProjectHeader } from '../../components/ProjectHeader';
import { Link } from '../../components/Link';
import { List, ListItem, ListItemText } from '../../components/List';
import { SiteLogo } from '../../components/app/SiteLogo';
import { GatsbyImageLink } from '../../components/GatsbyImageLink';

type ClassKey = 'main' | 'image';
const useStyles = makeStyles<Theme, ClassKey>((theme: Theme) => ({
    main: {
        flexGrow: 1,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        padding: theme.spacing(4),
        maxWidth: theme.breakpoints.width('md'),
        margin: '0 auto',
        width: '100%'
    },
    image: { borderRadius: theme.shape.borderRadius, boxShadow: theme.shadows[6] }
}));

export type TProjectPersonalWebsiteV2P = {
    data: GQLProjectPersonalWebsiteV2PageQuery;
};

function ProjectPersonalWebsiteV2(props: TReactFCP<TProjectPersonalWebsiteV2P>): TReactFCR {
    const styles: UseStyles<ClassKey> = useStyles();
    const { sitePage, projectsYaml, websiteV1 }: GQLProjectPersonalWebsiteV2PageQuery = props.data;
    const {
        title, category, type, spoiler, description, stack, links, featuredImage
    }: NonNullable<GQLProjectPersonalWebsiteV2PageQuery['projectsYaml']> = projectsYaml!;
    return (
        <main className={styles.main}>
            <SEO
                slug={sitePage!.path!}
                title={title!}
                seoTitle={`Codename: ${title!}`}
                description={description!}
                image={{
                    src: featuredImage!.childImageSharp!.original!.src!,
                    width: featuredImage!.childImageSharp!.original!.width!,
                    height: featuredImage!.childImageSharp!.original!.height!
                }}
            />
            <ProjectHeader
                title={title!}
                category={category!}
                type={type!}
                spoiler={spoiler!}
                stack={stack! as string[]}
                homepage={links!.homepage!}
                repository={links!.repository!}
                featuredImage={{
                    originalSrc: featuredImage!.childImageSharp!.original!.src!,
                    fluid: featuredImage!.childImageSharp!.fluid! as FluidObject
                }}
            />
            <Typography variant='h2' align='center' gutterTop paragraph>About</Typography>
            <Typography paragraph>
                I've wanted to make my own custom website for a long time, but have felt that I lacked the UI and
                frontend skills to design one to the standard I feel represents the professionalism, quality, and
                attention to detail I put into my other work. A{` `}
                <Link variant='internal' to='/projects/personal-website-v1/'>simple static site</Link> built with{` `}
                <Link variant='external' to='https://gohugo.io/'>Hugo</Link> has been serving as a placeholder for a few
                years and has done a good job. My work on{` `}
                <Link variant='internal' to='/projects/ourdatahelps/'>OurDataHelps</Link> and{` `}
                <Link variant='internal' to='/projects/trackside/'>Trackside</Link> helped me grow, learn, and practice
                enough that I felt I could finally make a site I'd be proud of. It also gave me an excuse to try
                building a website with <Link variant='external' to='https://gatsbyjs.com'>Gatsby</Link>.
            </Typography>
            <GatsbyImageLink
                to={websiteV1!.childImageSharp!.original!.src!}
                disableUnderline
                imageProps={{
                    className: styles.image,
                    alt: 'The first version of my personal website',
                    fluid: websiteV1!.childImageSharp!.fluid! as FluidObject,
                }}
            />
            <Typography variant='caption' align='center' color='light' paragraph>
                The first version of my personal website
            </Typography>
            <Typography paragraph>
                Expanding upon the content I included in the first iteration of the website, I've
            </Typography>
            <List paragraph>
                <ListItem>
                    <ListItemText primary={(
                        <React.Fragment>
                            Added <Link variant='internal' to='/career'>career</Link> highlights in a resume-like format
                        </React.Fragment>
                    )} />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={(
                            <React.Fragment>
                                Detailed the personal and professional{` `}
                                <Link variant='internal' to='/projects/'>projects</Link>{` `}
                                I've worked on thoughout my career
                            </React.Fragment>
                        )}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={(
                            <React.Fragment>
                                Created a <Link variant='internal' to='/blog/'>blog</Link> where I can share my
                                thoughts, experiences, and ideas
                            </React.Fragment>
                        )}
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary={(
                            <React.Fragment>
                                Captured the experience and credentials needed to represent myself as a professional
                                high performance <Link variant='internal' to='/driving/'>driver and instructor</Link>
                            </React.Fragment>
                        )}
                    />
                </ListItem>
                <ListItem icon={{ Component: SiteLogo, props: { disableLink: true, size: 'xxs' } }}>
                    <ListItemText
                        primary={`Designed an adorable puppy logo using my initials`}
                    />
                </ListItem>
            </List>
            <Typography paragraph>I hope you take a few minutes to look around and check out the site!</Typography>
        </main>
    );
}

export default (props: TReactFCP<TProjectPersonalWebsiteV2P>): TReactFCR => (
    <Page component={ProjectPersonalWebsiteV2} cprops={props} />
);

export const query = graphql`
    query ProjectPersonalWebsiteV2Page {
        sitePage(internalComponentName: {eq: "ComponentProjectsPersonalWebsiteV2"}) {
            path
        }
        projectsYaml(pid: {eq: "personal-website-v2"}) {
            title
            category
            type
            spoiler
            description
            stack
            links {
                homepage
                repository
            }
            featuredImage {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                    original {
                        src
                        width
                        height
                    }
                }
            }
        }
        websiteV1: file(relativePath: {eq: "images/projects/personal-website-v2/personal-website-v1.jpeg"}) {
            childImageSharp {
                fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp
                }
                original {
                    src
                }
            }
        }
    }
`;
